<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="建设单位:">
            <el-input v-model="searchModel.buildFirmName"></el-input>
          </el-form-item>
          <el-form-item label="施工单位:">
            <el-input v-model="searchModel.constrFirmName"></el-input>
          </el-form-item>
          <el-form-item label="工程浇捣部位:">
            <el-select
              v-model="searchModel.constructionPoint"
              placeholder="请选择浇捣部位"
              filterable
              clearable
              style="width: 200px"
            >
              <template v-for="item in ['桩基','土建','市政','其他']">
                <el-option
                  v-if="typeof(searchModel.constructionPoint) =='string'"
                  :key="item"
                  :label="item"
                  :value="''+item">
                </el-option>
                <el-option
                  v-else-if="typeof(searchModel.constructionPoint) =='number'"
                  :key="item"
                  :label="item"
                  :value="((''+item).trim() == '')?'':parseInt(item)">
                </el-option>
                <el-option
                  v-else
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="工程名称:">
            <el-input v-model="searchModel.proName"></el-input>
          </el-form-item>
          <el-form-item label="合格证编号:">
            <el-input v-model="searchModel.qualificationNum"></el-input>
          </el-form-item>
          <el-form-item label="合格证类型:">
            <el-select
              v-model="searchModel.qualificationType"
              placeholder="请选择类型"
              filterable
              clearable
              style="width: 200px"
            >
              <template v-for="(value, key) in {1:'合格证表1', 2:'合格证表2'}">
                <el-option
                  v-if="typeof(searchModel.qualificationType) =='string'"
                  :key="key"
                  :label="value"
                  :value="''+key">
                </el-option>
                <el-option
                  v-else-if="typeof(searchModel.qualificationType) =='number'"
                  :key="key"
                  :label="value"
                  :value="((''+key).trim() == '')?'':parseInt(key)">
                </el-option>
                <el-option
                  v-else
                  :key="key"
                  :label="value"
                  :value="key">
                </el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              v-auth="'出厂合格证_新增'"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="certOutDetail = undefined;dialogEdit = true;editAble = true"
            >新增
            </el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
          <vxe-table-column
            field="qualificationType"
            title="合格证类型"
            min-width="60"
            show-overflow="title"
            :formatter="({ cellValue, row, column }) => {
              return cellValue==1 ? '合格证表1' : '合格证表2';
            }
            "
            show-header-overflow="title"
          />
          <vxe-table-column
            field="qualificationNum"
            title="合格证编号"
            show-overflow="title"
            show-header-overflow="title"
            min-width="60"
          />
          <vxe-table-column
            field="contractNum"
            title="合同编号"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="proName"
            title="工程名称"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="compName"
            title="商砼单位 "
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="supplyDate"
            title="供货日期"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="supplyWeight"
            title="供货数量(m³) "
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="editNum"
            title="修改次数"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <!--        <vxe-table-column
          field="printNum"
          title="打印次数"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />-->
          <vxe-table-column
            field="price1"
            title="签收二维码 "
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          >
            <template v-slot="{ row }">
              <el-button type="primary" @click="handlerQrcode(row)" title="二维码">二维码</el-button>
            </template>
          </vxe-table-column>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="success" icon="el-icon-tickets" circle size="mini" @click="editAble=false;detailRow(row)"
                         v-auth="'出厂合格证_详情'" title="详情"/>
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editAble=true;detailRow(row)"
                         v-auth="'出厂合格证_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)"
                         v-auth="'出厂合格证_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <!-- 编辑采购合同 -->
      <certOutDetail2 :visible.sync="dialogEdit" :edit-data.sync="certOutDetail" @refresh="refreshData"  :edit-able ="editAble"/>
      <qr-code :visible.sync="codeVisible" :content="qrCodeContent" />
    </div>
  </PageContainer>
</template>

<script>
import { getCertOutPage, getPCertOutOne, delCertOut } from '@/apis/quality/certificateOut'
import XEUtils from 'xe-utils'
import certOutDetail2 from './certOutDetail2'
import QrCode from '@/components/QrCode'

export default {
  name: 'certificateOut',
  data () {
    return {
      qrCodeContent: '',
      codeVisible: false,
      editAble: true,
      certOutDetail: {},
      rules: {
        compName: [
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ]
      },
      dialogEdit: false,
      searchModel: {
        buildFirmName: '',
        constrFirmName: '',
        constructionPoint: '',
        proName: '',
        qualificationNum: '',
        qualificationType: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      }
    }
  },
  methods: {
    handlerQrcode (row) {
      getPCertOutOne({
        id: row.qualificationId
      }).then(res => {
        this.codeVisible = true
        const details = res.data
        this.qrCodeContent = `需方: ${details.buildFirmName};合格证编号: ${details.qualificationNum};
        供方: ${details.constrFirmName};资质证书编号: ${details.certificateNum};
         工程名称: ${details.proName};合同编号: ${details.contractNum};
          浇筑部位: ${details.constructionPoint};供货日期: ${details.supplyDate};
          混凝土标记: ${details.concreteFlag};实际供货数量: ${details.supplyWeight}`
      })
    },
    handleItemChange () {},
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      getCertOutPage({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    detailRow (row) {
      getPCertOutOne({
        id: row.qualificationId
      }).then(res => {
        const details = res.data
        const rowData = {}
        XEUtils.objectEach(details, (item, key) => {
          if (key == 'qualificationDetailList') {
            const tempArr = []
            details[key].forEach((eve, index) => {
              // 字符串转数组，便于编辑时数据双向绑定
              const checkItem = []
              details[key][index].checkItem = details[key][index].checkItem ? details[key][index].checkItem : ''
              eve.checkItem.split(',').forEach(item => checkItem.push(item))
              const checkTypicalValueJson = []
              details[key][index].checkTypicalValueJson = details[key][index].checkTypicalValueJson ? details[key][index].checkTypicalValueJson : ''
              eve.checkTypicalValueJson.split(',').forEach(item => checkTypicalValueJson.push(item))
              tempArr.push({
                checkItem: checkItem,
                checkReportNum2: eve.checkReportNum2, //
                checkTypicalValueJson: checkTypicalValueJson,
                qualificationDetailId: eve.qualificationDetailId
              })
            })
            rowData[key] = tempArr
          } else if (key == 'evaluate') {
            const evaluate = []
            details[key] = details[key] ? details[key] : ''
            details[key].split(',').forEach(item => evaluate.push(item))
            rowData[key] = evaluate
          } else {
            rowData[key] = details[key] ? details[key].toString() : ''
          }
        })
        this.certOutDetail = Object.assign({}, rowData)
        this.dialogEdit = true
      })
    },
    deleteRow (row) {
      this.$confirm('确定删除该出厂合格证记录?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        delCertOut({
          ids: [row.qualificationId]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    }
  },
  mounted () {
    this.refreshData()
  },
  components: {
    certOutDetail2,
    QrCode
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
