<template>
  <el-dialog  class="abow_dialog"  v-bind="{title:dialogTitle,width:'80%'}"
              :visible.sync="visibleDialog"
              @closed="handlerClose"
              @open="handlerOpen">
    <div ref="certOutDetail" style="display: flex;flex-direction: column" id="settleMortarTable">
      <table border="0" cellpadding="0" cellspacing="0" width="96%" style="margin:0 20px 0 20px;">
        <tbody>
          <tr style="height: 50px">
            <td align="center" width="100%" style="font-size:18pt; font-weight:bold;">
              预拌混凝土合格证
            </td>
          </tr>
        </tbody>
      </table>
      <table border="0" cellpadding="0" cellspacing="0" width="96%" style="margin:0 20px 0 20px;">
        <tbody>
          <tr>
            <td align="left" width="15%" style="padding-left:20px;">
              <span>需方：</span>
            </td>
            <td align="left"  width="35%">
              <el-autocomplete
                class="inline-input"
                v-if="editAble"
                v-model="submitForm.buildFirmName"
                :fetch-suggestions="queryRelaSearch"
                placeholder="请输入内容"
                @select="handleRelaSelect"
                :debounce="10"
                style="width: 90%"
                @change = "$forceUpdate()"
              ></el-autocomplete>
              <span v-else>{{submitForm.buildFirmName}}</span>
            </td>
            <td align="left" style="padding-left:20px;" >
              <span>合格证编号：</span>
            </td>
            <td align="left">
              <el-input v-if="editAble"
                        v-model="submitForm.qualificationNum"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.qualificationNum}}</span>
            </td>
          </tr>
          <tr>
            <td align="left" style="padding-left:20px;">
              <span>供方：</span>
            </td>
            <td align="left">
              <el-input v-if="editAble"
                        v-model="submitForm.constrFirmName"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.constrFirmName}}</span>
            </td>
            <td align="left" style="padding-left:20px;">
              <span>资质证书编号：</span>
            </td>
            <td align="left">
              <el-input v-if="editAble"
                        v-model="submitForm.certificateNum"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.certificateNum}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:2px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="15%" align="center">
              <span>工程名称</span>
            </td>
            <td width="35%" align="center">
              <el-autocomplete
                v-if="editAble"
                class="inline-input"
                v-model="submitForm.proName"
                :fetch-suggestions="queryProjSearch"
                placeholder="请输入内容"
                @select="handleProjSelect"
                :debounce="10"
                style="width: 90%"
                @change = "$forceUpdate()"
              ></el-autocomplete>
              <span v-else>{{submitForm.proName}}</span>
            </td>
            <td width="15%" align="center">
              <span>合同编号</span>
            </td>
            <td width="35%" align="center">
              <el-input
                v-if="editAble"
                v-model="submitForm.contractNum"
                style="width: 90%"
              />
              <span v-else>{{submitForm.contractNum}}</span>
            </td>
          </tr>
          <tr>
            <td width="15%" align="center">
              <span>浇筑部位</span>
            </td>
            <td width="35%" align="center">
              <el-select
                placeholder="请选择浇筑部位"
                filterable
                clearable
                v-if="editAble"
                v-model="submitForm.constructionPoint"
                style="width: 90%"
              >
                <template v-for="item in ['桩基','土建','市政','其他']">
                  <el-option
                    v-if="typeof(submitForm.constructionPoint) =='string'"
                    :key="item"
                    :label="item"
                    :value="''+item">
                  </el-option>
                  <el-option
                    v-else-if="typeof(submitForm.constructionPoint) =='number'"
                    :key="item"
                    :label="item"
                    :value="((''+item).trim() == '')?'':parseInt(item)">
                  </el-option>
                  <el-option
                    v-else
                    :key="item.constructionPoint"
                    :label="item"
                    :value="item">
                  </el-option>
                </template>
              </el-select>
              <span v-else>{{submitForm.constructionPoint}}</span>
            </td>
            <td width="15%" align="center">
              <span>供货日期</span>
            </td>
            <td width="35%" align="center">
              <el-date-picker
                v-if="editAble"
                v-model="submitForm.supplyDate"
                type="date"
                placeholder="选择日期时间"
                style="width: 90%"
                value-format="yyyy-MM-dd"
              />
              <span v-else>{{submitForm.supplyDate}}</span>
            </td>
          </tr>
          <tr>
            <td width="15%" align="center">
              <span>混凝土标记</span>
            </td>
            <td width="35%" align="center">
              <el-input
                v-if="editAble"
                v-model="submitForm.concreteFlag"
                style="width: 90%"
              />
              <span v-else>{{submitForm.concreteFlag}}</span>
            </td>
            <td width="15%" align="center">
              <span>实际供货数量</span>
            </td>
            <td width="35%" align="center">
              <el-input v-if="editAble"
                        v-model="submitForm.supplyWeight"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.supplyWeight}}</span>
            </td>
          </tr>
          <tr>
            <td width="15%" align="center">
              <span>标记外技术要求</span>
            </td>
            <td width="35%" align="center">
              <el-input v-if="editAble"
                        v-model="submitForm.otherTechneed"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.otherTechneed}}</span>
            </td>
            <td width="15%" align="center">
              <span>配合比编号</span>
            </td>
            <td width="35%" align="center">
              <el-input v-if="editAble"
                        v-model="submitForm.matchingNo"
                        style="width: 90%"
              />
              <span v-else>{{submitForm.matchingNo}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="left" border="1" cellpadding="5" cellspacing="0" width="96%" style="margin:-5px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="15%" align="center" :rowspan="submitForm.qualificationDetailList.length+addRowSpan">
              <span>检验结果</span>
            </td>
            <td :colspan="submitForm.qualificationDetailList[0].checkItem.length" align="center">
              <span>检验项目及代表值</span>
            </td>
            <td width="4%" align="center" :rowspan="submitForm.qualificationDetailList.length+ addRowSpan+1" v-if="editAble">
              <el-button style="white-space: pre-wrap; overflow-wrap: break-word; width: 20px; text-align: center;padding:3px" @click="addCheckItem">添加检验项目</el-button>
            </td>
            <td width="17%" align="center" rowspan="2" min-width="17%">
              <span>检验报告编号</span>
            </td>
          </tr>
          <tr>
            <template  v-for="(item,index) in submitForm.qualificationDetailList[0].checkItem">
              <td :width="(itemWidthSum/(itemCnt)).toFixed(2)+'%'" align="center" :key="index">
                <el-input v-if="editAble"
                          v-model="submitForm.qualificationDetailList[0].checkItem[index]"
                          style="width: 98%"
                />
                <span v-else>{{item}}</span>
              </td>
            </template>
          </tr>
          <tr v-for="(eveQualDetail, qdindex) in submitForm.qualificationDetailList" :key="qdindex">
            <template  v-for="(typicalValue,tindex) in eveQualDetail.checkTypicalValueJson">
              <td :width="(itemWidthSum/(itemCnt)).toFixed(2)+'%'" align="center" :key="tindex">
                <!--                <span v-if="editAble">{{item}}</span>-->
                <el-input
                  v-model="eveQualDetail.checkTypicalValueJson[tindex]"
                  v-if="editAble"
                  style="width: 98%"
                />
                <span v-else>{{typicalValue}}</span>
              </td>
            </template>
            <td align="center" >
              <el-input
                style="width: 98%"
                v-if="editAble"
                v-model="eveQualDetail.checkReportNum2"
              />
              <span v-else>{{eveQualDetail.checkReportNum2}}</span>
            </td>
          </tr>
          <tr v-if="editAble">
            <td :colspan="itemCnt" align="center"  v-if="editAble">
              <el-button  @click="addQualDetails">增行</el-button>
            </td>
            <td align="center" :rowspan="editAble?2:1">
            </td>
          </tr>
          <tr>
            <td width="15%" align="center"><span style="font-size: 14px; font-weight: bold;">评定结果</span></td>
            <template  v-for="(item,index) in submitForm.evaluate">
              <td :width="(60/(itemCnt)).toFixed(2)+'%'" align="center" :key="index">
                <!--                <span v-if="editAble">{{item}}</span>-->
                <el-input
                  v-if="editAble"
                  v-model="submitForm.evaluate[index]"
                  style="width: 98%"
                />
                <span v-else>{{item}}</span>
              </td>
            </template>
          </tr>
        </tbody>
      </table>

      <table align="left" cellpadding="5" border="1" cellspacing="0" width="96%" style="margin:-4px 20px 2px 20px;">
        <tbody>
          <tr>
            <td width="15%" align="center" >
              <span>评定依据</span>
            </td>
            <td width="85%" align="left">
              <p style="margin-top:15px">1.评定依据国家标准《预拌混凝土》GB/T14902-2012质量要求；</p>
              <p style="margin-top:10px">2.当需方提出其他混凝土性能要求时，应按国家现行有关标准规定方法进行试验评定；</p>
              <p style="margin-top:10px;margin-bottom: 15px">3.无相应标准时应按合同约定方法进行试验和评定。</p>
            </td>
          </tr>
        </tbody>
      </table>
      <table align="left" border="0" cellpadding="0" cellspacing="0" width="96%" style="margin:-4px 20px 2px 20px;">
        <tbody>
          <tr style="height:50px">
            <td width="16%" align="left">
              <span>技术负责人:</span>
            </td>
            <td width="17%" align="left">quto
              <span></span>
            </td>
            <td width="16%" align="left">
              <span>编制人:</span>
            </td>
            <td width="17%" align="left">
              <span></span>
            </td>
            <td width="16%" align="left">
              <span>(出厂合格章):</span>
            </td>
            <td width="18%" align="left">
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div slot="footer" class="dialog-footer" v-show="editAble" >
      <el-button @click="visibleDialog = false" size="mini">取消</el-button>
      <el-button type="primary" @click="submit" size="mini">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addModCertOut } from '@/apis/quality/certificateOut'
import { getRelaList } from '@/apis/saleManage/contractMan'
import { gainProjCallback } from '@/apis/infoManage/project'
import { mapState } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    editData: {
      type: Object
    },
    editAble: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    ...mapState('account', ['userInfo']),
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () {
        return this.visible
      }
    }
  },
  data () {
    return {
      projectItems: [],
      relaList: [],
      itemWidthSum: 64,
      addRowSpan: 3,
      itemCnt: 4,
      loading: false,
      submitForm: {
        constructionPoint: '',
        concreteFlag: '',
        contractNum: '',
        buildFirmName: '',
        constrFirmName: '',
        certificateNum: '',
        matchingNo: '',
        otherTechneed: '',
        parentQualificationId: '',
        proName: '',
        evaluate: ['', '', '', ''],
        qualificationDetailList: [
          {
            checkItem: ['抗压强度(Mpa)', '抗折强度(Mpa)', '抗渗等级', ''],
            checkReportNum2: '', //
            checkTypicalValueJson: ['', '', '', ''],
            qualificationDetailId: ''
          }
        ],
        qualificationId: '',
        qualificationNum: '',
        qualificationType: 2,
        supplyDate: '',
        supplyWeight: 0
      },
      dialogTitle: '',
      rules: {
      }
    }
  },
  methods: {
    handleProjSelect (item) {
    },
    queryProjSearch (queryString, cb) {
      const projectItems = this.projectItems
      const results = queryString ? projectItems.filter(item => (item.prjname.indexOf(queryString) > -1)) : projectItems
      // 调用 callback 返回建议列表的数据
      cb(results.splice(0, (results.length > 30 ? 30 : results.length)))
    },
    handleRelaSelect (item) {
    },
    queryRelaSearch (queryString, cb) {
      const relaList = this.relaList
      const results = queryString ? relaList.filter(item => (item.firm.indexOf(queryString) > -1)) : relaList
      // 调用 callback 返回建议列表的数据
      cb(results.splice(0, (results.length > 30 ? 30 : results.length)))
    },
    addCheckItem () {
      const qualificationDetailList = this.submitForm.qualificationDetailList
      qualificationDetailList.forEach((eveDetail) => {
        eveDetail.checkItem.push('')
        eveDetail.checkTypicalValueJson.push('')
      })
      this.submitForm.evaluate.push('')
      this.submitForm.qualificationDetailList = qualificationDetailList
      this.itemCnt = this.submitForm.qualificationDetailList[0].checkItem.length
    },
    addQualDetails () {
      const qualificationDetailList = this.submitForm.qualificationDetailList
      const checkItemArr = []
      const checkTypicalValArr = []
      this.submitForm.qualificationDetailList[0].checkItem.forEach(item => {
        checkItemArr.push('')
        checkTypicalValArr.push('')
      })
      qualificationDetailList.push(
        {
          checkItem: checkItemArr,
          checkReportNum2: '', //
          checkTypicalValueJson: checkTypicalValArr,
          qualificationDetailId: ''
        })
      this.submitForm.qualificationDetailList = qualificationDetailList
    },
    submit () {
      const qualificationDetailList = []
      this.submitForm.qualificationDetailList.forEach(item => {
        qualificationDetailList.push({
          checkItem: item.checkItem.join(','),
          checkReportNum2: item.checkReportNum2, //
          checkTypicalValueJson: item.checkTypicalValueJson.join(','),
          qualificationDetailId: item.qualificationDetailId
        })
      })
      addModCertOut({
        concreteFlag: this.submitForm.concreteFlag,
        contractNum: this.submitForm.contractNum,
        matchingNo: this.submitForm.matchingNo,
        buildFirmName: this.submitForm.buildFirmName,
        constrFirmName: this.submitForm.constrFirmName,
        certificateNum: this.submitForm.certificateNum,
        otherTechneed: this.submitForm.otherTechneed,
        parentQualificationId: this.submitForm.parentQualificationId,
        proName: this.submitForm.proName,
        evaluate: this.submitForm.evaluate.join(','),
        constructionPoint: this.submitForm.constructionPoint,
        qualificationDetailList: qualificationDetailList,
        qualificationId: this.submitForm.qualificationId,
        qualificationNum: this.submitForm.qualificationNum,
        qualificationType: this.submitForm.qualificationType,
        supplyDate: this.submitForm.supplyDate,
        supplyWeight: this.submitForm.supplyWeight
      }).then(res => {
        if (res.code === 200) {
          if (this.submitForm.qualificationId != '') {
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
          this.visibleDialog = false
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlerOpen () {
      this.submitForm.constrFirmName = this.userInfo.compName
      if (this.editAble) {
        this.dialogTitle = this.editData ? '编辑出厂合格证' : '新建出厂合格证'
        this.itemWidthSum = 64
        this.addRowSpan = 3
      } else {
        this.itemWidthSum = 68
        this.addRowSpan = 2
        this.dialogTitle = '出厂合格证详情'
      }
      if (this.editData) {
        Object.keys(this.submitForm).forEach((key) => {
          const value = this.editData[key]
          if (value) this.submitForm[key] = value
        })
      }
      this.itemCnt = this.submitForm.qualificationDetailList[0].checkItem.length
    },
    handlerClose () {
      this.$emit('update:editData', null)
      this.editData = undefined
      this.submitForm = {
        constructionPoint: '',
        concreteFlag: '',
        contractNum: '',
        buildFirmName: '',
        constrFirmName: '',
        certificateNum: '',
        matchingNo: '',
        otherTechneed: '',
        parentQualificationId: '',
        proName: '',
        evaluate: ['', '', '', ''],
        qualificationDetailList: [
          {
            checkItem: ['抗压强度(Mpa)', '抗折强度(Mpa)', '抗渗等级', ''],
            checkReportNum2: '', //
            checkTypicalValueJson: ['', '', '', ''],
            qualificationDetailId: ''
          }
        ],
        qualificationId: '',
        qualificationNum: '',
        qualificationType: 2,
        supplyDate: '',
        supplyWeight: 0
      }
    }
  },
  mounted () {
    // 关联客户名称  限定企业帐号
    getRelaList({
      relaType: 1 // 建设单位
    }).then(res => {
      const relaList = []
      res.data.forEach(item => {
        const temp = item
        temp.value = item.firm
        relaList.push(temp)
      })
      this.relaList = relaList
    })
    gainProjCallback({ prjname: '', prjnum: '' }).then(res => {
      const projItems = []
      res.data.forEach(item => {
        const temp = item
        temp.value = item.projName
        projItems.push(temp)
      })
      this.projectItems = projItems
    })
  }
}
</script>

<style lang="scss" scoped>
.abow_dialog {
  .el-dialog {
    margin: 0 auto !important;
    height:auto;
    overflow: hidden;
    overflow-y: scroll;
    /* .el-dialog__body {
     position: relative;
       left: 0;
       bottom: 0;
       right: 0;
       padding: 0;
       z-index: 1;
       overflow: hidden;
       overflow-y: scroll;
     }*/
  }
}
table tr{
  height:35px;
}
table{
  color:black;
}
</style>
