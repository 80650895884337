import http from '@/utils/http'
// 出厂合格证
// 合格证信息管理分页查询
export const getCertOutPage = p => http.post('/unibu/cert/page', p)

// 合格证信息详情
export const getPCertOutOne = p => http.post('/unibu/cert/one', p)

// 新增或修改合格证信息
export const addModCertOut = p => http.post('/unibu/cert/addMod', p)

// 逻辑删除合格证信息
export const delCertOut = p => http.post('/unibu/cert/del', p)
